<template>
  <div class="drop-btn">
    <div
      class="btn"
      @click.stop="tabChange()"
      v-if="dropList.length > 0"
      :style="[sty, { width: width, height: height }]"
    >
      <span>{{ dropList[currentIndex].name }}</span>
      <i class="icon" :class="status ? 'active' : ''"></i>
      <div
        class="drop"
        :class="status ? 'dropAni' : ''"
        :style="{
          bottom: `-${0.66 + 0.31 * (dropList.length - 1)}rem`,
          width: width,
        }"
      >
        <div
          class="drop-item"
          @click.stop="dropClick(item, index)"
          v-for="(item, index) in dropList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dropList: {
      type: Array,
      default: [],
    },
    sty: {
      type: Object,
      default: () => {},
    },
    width: {
      type: String,
      default: () => "1.85rem",
    },
    height: {
      type: String,
      default: () => "0.3rem",
    },
  },
  data() {
    return {
      status: false,
      currentIndex: 0,
    };
  },
  mounted() {
    document.body.addEventListener("click", this.close);
  },
  destroyed() {
    document.body.removeEventListener("click", this.close);
  },
  methods: {
    dropClick(item, index) {
      this.currentIndex = index;
      this.$emit("dropItemClick", item);
      this.status = false;
    },
    tabChange() {
      this.status = !this.status;
    },
    close() {
      this.status = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.drop-btn {
  position: relative;
  width: 1.65rem;
  height: 0.31rem;
  line-height: 0.31rem;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.2rem;
  // box-shadow: inset 0px 0px 7px 3px #267b81;
  // border-radius: 0.2rem 0 0.2rem 0;
  background: url("../../assets/images/mobile/m-drop-bg.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 0.1rem;
  font-size: 0.12rem;
  cursor: pointer;
  // margin-left: 0.2rem;
  color: #c6c6c6;

  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  i {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.06rem 0.07rem 0 0.07rem;
    border-color: #999999 transparent transparent transparent;
    transition: all 0.5s;
  }

  .active {
    transform: rotate(180deg);
  }

  .drop {
    display: none;
    position: absolute;
    left: 0;
    width: 1.85rem;
    // height: 0.7rem;
    background: rgba(83, 83, 83, 0.9);
    box-shadow: 0px 1px 5px 1px #eee;
    box-sizing: border-box;
    padding: 15px 0;
    text-align: center;
    z-index: 19;

    .drop-item {
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background: rgb(120, 120, 120);
      }
    }
  }

  .dropAni {
    display: block;
    opacity: 0;
    animation: dropAni 2s forwards;
  }
}

@keyframes dropAni {
  to {
    opacity: 1;
  }
}
</style>
