export const tableRecordList3 = [
  {
    title: "回收时间",
    width: "30%",
    index: "updated_at",
    center: "center",
    render: true
  },
  {
    title: "物品",
    width: "50%",
    index: "code",
    center: "center",
    render: true
  },
  // {
  //   title: "详细",
  //   width: "25%",
  //   index: "status_alias",
  //   center: "center",
  // },
  {
    title: "回购价",
    width: "15%",
    index: "bean",
    center: "center",
  },
];
export const tableRecordList2 = [
  {
    title: "提货发起时间",
    width: "40%",
    index: "updated_at",
    center: "center",
    render: true
  },
  {
    title: "物品",
    width: "35%",
    index: "name",
    center: "center",
    render: true
  },
  {
    title: "状态",
    width: "25%",
    index: "status_alias",
    center: "center",
    render: true
  },
  {
    title: "回购价",
    width: "20%",
    index: "bean",
    center: "center",
  },
];

export const tableData = {
  total: 15,
  pageSize: 15,
  list: [
    {}
  ],
};
export const acceptData = {
  total: 0,
  pager: 0,
  list: [
    {}
  ],
};
